import VerticalMenu_VerticalMenuTextWithSeparatorsSkinComponent from '@wix/thunderbolt-elements/src/components/VerticalMenu/viewer/skinComps/VerticalMenuTextWithSeparatorsSkin/VerticalMenuTextWithSeparatorsSkin.skin';
import VerticalMenu_VerticalMenuTextWithSeparatorsSkinController from '@wix/thunderbolt-elements/src/components/VerticalMenu/viewer/VerticalMenu.controller';


const VerticalMenu_VerticalMenuTextWithSeparatorsSkin = {
  component: VerticalMenu_VerticalMenuTextWithSeparatorsSkinComponent,
  controller: VerticalMenu_VerticalMenuTextWithSeparatorsSkinController
};


export const components = {
  ['VerticalMenu_VerticalMenuTextWithSeparatorsSkin']: VerticalMenu_VerticalMenuTextWithSeparatorsSkin
};

